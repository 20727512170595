import { useQuery } from '@apollo/client';

import {
  BofFollowerCountDocument,
  BofFollowersBriefDocument,
  BofFollowersDocument,
  FollowerOrder,
  FollowerUniqueType,
  HomeState,
} from '../generated/backend/graphql';

export function useFetchFollowers<T extends { followers: T['followers'] }>(
  areaIds: string[] | undefined,
  minRooms: number | undefined,
  maxRooms: number | undefined,
  minLivingArea: number | undefined,
  maxLivingArea: number | undefined,
  createdAtFrom: string | undefined,
  createdAtTo: string | undefined,
  unique: FollowerUniqueType | undefined,
  active: boolean | undefined,
  homeStates: HomeState[] | undefined,
  excludeHomeId: string | undefined,
  page: number,
  pageSize: number,
  skip: boolean,
  extent: 'brief' | 'complete',
  pagination: 'noPagination' | 'pagination',
) {
  const buildFilter = (
    withPagination: boolean,
    currentPage: number = page,
  ) => ({
    areaIds,
    minRooms,
    maxRooms,
    minLivingArea,
    maxLivingArea,
    unique,
    active,
    homeStates,
    latestCount: withPagination ? pageSize : undefined,
    offset: withPagination ? (currentPage - 1) * pageSize : undefined,
    excludeHomeId,
    createdAt: {
      startDate: createdAtFrom,
      endDate: createdAtTo,
    },
  });

  // Get the total count to set the pagination
  const { data: followerCountData } = useQuery(BofFollowerCountDocument, {
    variables: {
      followerOptions: {
        filter: buildFilter(false),
      },
    },
    skip,
  });
  const totalCount = followerCountData?.followerCount || 0;
  const pageCount =
    pagination === 'pagination' && pageSize
      ? Math.max(1, Math.ceil((totalCount || 0) / pageSize))
      : 1;
  const currentPage = Math.min(page, pageCount);

  // Use pagination data to fetch the actual followers
  const {
    loading: followersLoading,
    error: followersError,
    data: followersData,
  } = useQuery<T>(
    extent === 'complete' ? BofFollowersDocument : BofFollowersBriefDocument,
    {
      variables: {
        followerOptions: {
          filter: buildFilter(pagination === 'pagination', currentPage),
          orderBy: FollowerOrder.UpdatedAt,
        },
      },
      skip,
    },
  );

  const { followers } = followersData || {};

  return {
    followers,
    followersLoading,
    followersError,
    totalCount,
    pageCount,
    currentPage,
  };
}
