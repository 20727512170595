import { useMutation, useQuery } from '@apollo/client';
import EmailIcon from '@mui/icons-material/Email';
import LaunchIcon from '@mui/icons-material/Launch';
import SmsIcon from '@mui/icons-material/Sms';
import { Alert, Box, Button, Paper, Stack, Tab, Tabs } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { sv as locale } from '@norban/locale';
import { LeadTemplateWarning } from '@norban/utils/dist';
import React, { useState } from 'react';

import EmailDialog from '../../../components/EmailDialog';
import SMSDialog from '../../../components/SMSDialog';
import {
  BofLeadsTemplatesDocument,
  BofSendSmsDocument,
  BulkEmailType,
  BulkEmailUnsubscribeType,
} from '../../../generated/backend/graphql';
import usePopupAlert from '../../../hooks/usePopupAlert';

import LeadTemplatesAssignedAgentPicker from './LeadTemplatesAssignedAgentPicker';

type Props = {
  userId: string;
  homeId?: string;
  email?: string;
  phone?: string;
  assignedAgentId?: string;
};

type SelectedGroup = 'seller' | 'sms' | 'buyer';

type SelectedTab =
  | 'onboarding'
  | 'interest1'
  | 'interest2'
  | 'sms'
  | 'sms2'
  | 'interest3'
  | 'hidden'
  | 'tagYourHome'
  | 'becomeFollower'
  | 'floorPlan'
  | 'uploadPhotos'
  | 'campaign'
  | 'follower';

type SelectedTabMap = {
  [key in SelectedTab]: { key: BulkEmailType; description: string };
};

const useStyles = makeStyles(() => ({
  message: {
    whiteSpace: 'pre-wrap',
  },
}));

const SelectedTabTemplateMap: SelectedTabMap = {
  onboarding: {
    key: BulkEmailType.LeadTemplateOnboarding,
    description: 'Bearbetningsmall onboarding',
  },
  interest1: {
    key: BulkEmailType.LeadTemplateInterest,
    description: 'Bearbetningsmall intresse',
  },
  interest2: {
    key: BulkEmailType.LeadTemplateInterest2,
    description: 'Bearbetningsmall intresse 2',
  },
  sms: {
    key: BulkEmailType.LeadTemplateSms,
    description: 'Bearbetningsmall SMS',
  },
  sms2: {
    key: BulkEmailType.LeadTemplateSms2,
    description: 'Bearbetningsmall SMS 2',
  },
  interest3: {
    key: BulkEmailType.LeadTemplateInterest3,
    description: 'Bearbetningsmall intresse 3',
  },
  hidden: {
    key: BulkEmailType.LeadTemplateHidden,
    description: 'Bearbetningsmall dold',
  },
  follower: {
    key: BulkEmailType.LeadTemplateFollower,
    description: 'Bearbetningsmall follower',
  },
  tagYourHome: {
    key: BulkEmailType.LeadTemplateTagYourHome,
    description: 'Bearbetningsmall tagYourHome',
  },
  becomeFollower: {
    key: BulkEmailType.LeadTemplateBecomeFollower,
    description: 'Bearbetningsmall becomeFollower',
  },
  floorPlan: {
    key: BulkEmailType.LeadTemplateFloorPlan,
    description: 'Bearbetningsmall floorPlan',
  },
  uploadPhotos: {
    key: BulkEmailType.LeadTemplateUploadPhotos,
    description: 'Bearbetningsmall uploadPhotos',
  },
  campaign: {
    key: BulkEmailType.LeadTemplateCampaign,
    description: 'Bearbetningsmall campaign',
  },
};

const LeadTemplates = ({
  userId,
  homeId = undefined,
  email = undefined,
  phone = undefined,
  assignedAgentId = undefined,
}: Props) => {
  const L = locale.backoffice;

  const classes = useStyles();

  const { PopupAlert, showPopupAlert } = usePopupAlert();

  const [currentGroup, setCurrentGroup] = useState<SelectedGroup>(
    homeId ? 'seller' : 'buyer',
  );
  const [currentTab, setCurrentTag] = useState<SelectedTab>(
    homeId ? 'interest1' : 'follower',
  );
  const [dialogOpen, setDialogOpen] = useState(false);
  const [SMSDialogOpen, setSMSDialogOpen] = useState(false);
  const [selectedAgentId, setSelectedAgentId] = useState<string | null>(null);

  const { data, loading, error } = useQuery(BofLeadsTemplatesDocument, {
    variables: {
      templateId: currentTab,
      userId,
      assignedAgentId: selectedAgentId ?? undefined,
    },
    skip: !email,
  });

  const { leadTemplate } = data || {};
  const numHomeId =
    homeId && currentTab !== 'follower' ? parseInt(homeId, 10) : undefined;

  let currentAssignedAgentId;
  if (currentTab === 'follower') {
    if (selectedAgentId) {
      currentAssignedAgentId = selectedAgentId;
    } else if (leadTemplate?.assignedAgentId) {
      currentAssignedAgentId = leadTemplate?.assignedAgentId;
    }
  }
  const currentTemplate = SelectedTabTemplateMap[currentTab].key;

  const [sendSMS] = useMutation(BofSendSmsDocument);

  const handleSendSMS = async (message: string) => {
    if (!assignedAgentId) {
      showPopupAlert(L.leadTemplates.noAssignedAgent, 'error');
      return;
    }

    if (!userId) {
      showPopupAlert(L.leadTemplates.noUser, 'error');
      return;
    }

    if (!message) {
      showPopupAlert(L.leadTemplates.noMessage, 'error');
      return;
    }

    try {
      await sendSMS({
        variables: {
          input: {
            message,
            senderId: assignedAgentId,
            recipientId: userId,
            template: currentTemplate,
          },
        },
      });
      showPopupAlert(L.leadTemplates.smsSent, 'success');
    } catch (error) {
      showPopupAlert(L.leadTemplates.failedSendingSMS, 'error');
    }
  };

  const templateInvalid = currentTab === 'follower' && !currentAssignedAgentId;

  const smsTabs = [
    <Tab key="sms" value="sms" label={L.leadTemplates.sms1} />,
    <Tab key="sms2" value="sms2" label={L.leadTemplates.sms2} />,
  ];
  const sellerTabs = [
    <Tab
      key="onboarding"
      value="onboarding"
      label={L.leadTemplates.onboarding}
    />,
    <Tab key="interest1" value="interest1" label={L.leadTemplates.interest1} />,
    <Tab key="interest2" value="interest2" label={L.leadTemplates.interest2} />,
    <Tab key="interest3" value="interest3" label={L.leadTemplates.interest3} />,
    <Tab key="hidden" value="hidden" label={L.leadTemplates.hidden} />,
    <Tab
      key="tagYourHome"
      value="tagYourHome"
      label={L.leadTemplates.tagYourHome}
    />,
    <Tab
      key="becomeFollower"
      value="becomeFollower"
      label={L.leadTemplates.becomeFollower}
    />,
    <Tab key="floorPlan" value="floorPlan" label={L.leadTemplates.floorPlan} />,
    <Tab
      key="uploadPhotos"
      value="uploadPhotos"
      label={L.leadTemplates.uploadPhotos}
    />,
    <Tab key="campaign" value="campaign" label={L.leadTemplates.campaign} />,
  ];
  const buyerTabs = [
    <Tab key="follower" value="follower" label={L.leadTemplates.follower} />,
  ];

  return (
    <>
      <Paper>
        <Tabs
          variant="scrollable"
          value={currentGroup}
          onChange={(_event, newValue) => {
            setCurrentGroup(newValue);
            if (newValue === 'seller') {
              setCurrentTag('interest1');
            } else if (newValue === 'sms') {
              setCurrentTag('sms');
            } else {
              setCurrentTag('follower');
            }
          }}
        >
          {homeId && (
            <Tab key="seller" value="seller" label={L.leadTemplates.seller} />
          )}
          {homeId && <Tab key="sms" value="sms" label={L.leadTemplates.sms} />}
          <Tab key="buyer" value="buyer" label={L.leadTemplates.buyer} />
        </Tabs>
        <Tabs
          variant="scrollable"
          value={currentTab}
          onChange={(_event, newValue) => setCurrentTag(newValue)}
        >
          {currentGroup === 'seller' && sellerTabs.map(tab => tab)}
          {currentGroup === 'sms' && smsTabs.map(tab => tab)}
          {currentGroup === 'buyer' && buyerTabs.map(tab => tab)}
        </Tabs>
        <Box p={2}>
          <Stack>
            <Box mb={2}>
              {/* horizontal stack */}
              <Stack direction="row" spacing={2}>
                {!['sms', 'sms2'].includes(currentTab) && (
                  <Button
                    disabled={templateInvalid}
                    color="secondary"
                    size="small"
                    variant="contained"
                    startIcon={<EmailIcon />}
                    onClick={() => setDialogOpen(true)}
                  >
                    {L.sendEmail}
                  </Button>
                )}
                {['sms', 'sms2'].includes(currentTab) && (
                  <Stack direction="row" spacing={2}>
                    <Button
                      color="secondary"
                      size="small"
                      variant="contained"
                      disabled={!phone}
                      startIcon={<SmsIcon />}
                      onClick={() => setSMSDialogOpen(true)}
                    >
                      {L.leadTemplates.sendSMS}
                    </Button>
                    <Button
                      color="secondary"
                      size="small"
                      variant="contained"
                      disabled={!phone}
                      startIcon={<LaunchIcon />}
                      href={`sms:${phone}?body=${encodeURIComponent(leadTemplate?.message ?? '')}`}
                    >
                      {L.leadTemplates.openInApp}
                    </Button>
                  </Stack>
                )}
              </Stack>
            </Box>
            {(error || !email) && (
              <Alert severity="error">
                {error ? error.message : 'Användaren saknar email'}
              </Alert>
            )}
            {templateInvalid && (
              <Alert severity="error">
                <strong>Hittade ingen ansvarig mäklare att mejla från!</strong>
              </Alert>
            )}
            {!templateInvalid &&
              data?.leadTemplate.warnings.length != null &&
              data.leadTemplate.warnings.length > 0 && (
                <Alert severity="warning">
                  <strong>
                    {`Viss information nedan saknas eller kan vara felaktig: 
                  ${data.leadTemplate.warnings
                    .map(
                      warning =>
                        L.leadTemplates[warning as keyof LeadTemplateWarning],
                    )
                    .join(', ')}`}
                  </strong>
                </Alert>
              )}
            {data?.leadTemplate.warnings.length != null &&
              data.leadTemplate.warnings.length === 0 && (
                <Alert severity="success">
                  <strong>Meddelandet är fullständigt!</strong>
                </Alert>
              )}
            {currentTab === 'follower' && (
              <LeadTemplatesAssignedAgentPicker
                selectedAgentId={selectedAgentId}
                onSelectAgentId={setSelectedAgentId}
              />
            )}
            <pre className={classes.message}>
              {loading && 'Laddar...'}
              {!loading && !error && <span>{leadTemplate?.message}</span>}
            </pre>
          </Stack>
        </Box>
      </Paper>
      <EmailDialog
        defaultType={currentTemplate}
        recipients={[{ id: userId, email: email ?? '' }]}
        userFilter={{}}
        userFilterCount={-1}
        open={dialogOpen}
        onSent={() => {}}
        onClose={() => setDialogOpen(false)}
        typeDatas={[
          {
            type: currentTemplate,
            unsubscribeType: BulkEmailUnsubscribeType.None,
            description: SelectedTabTemplateMap[currentTab].description,
            subject: leadTemplate?.subject ?? '',
            message: leadTemplate?.message ?? '',
            signatureType: 'withLogo',
          },
        ]}
        skipFirstTwoSteps
        homeId={numHomeId}
        assignedAgentId={currentAssignedAgentId}
      />
      <SMSDialog
        message={leadTemplate?.message ?? ''}
        phone={phone ?? ''}
        open={SMSDialogOpen}
        onClose={() => setSMSDialogOpen(false)}
        onSend={handleSendSMS}
      />
      <PopupAlert />
    </>
  );
};

export default LeadTemplates;
